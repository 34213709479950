import { useEffect, useState } from 'react';
import { Button } from '@sirkka-health/booking-system-ui';
import './EventCard.scss';
import ReadMore from '../ReadMore';
import { getRequest } from '../../services/api';
import { EventCardProps } from '@/types';

export default function EventCard({ eventItem, onOpenModal, onHover, onLeave }: EventCardProps) {
	const [isHovered, setIsHovered] = useState(false);
	const [services, setServices] = useState<any[]>([]);
	const [showAllServices, setShowAllServices] = useState(false);

	const isEventFinished =
		new Date(new Date(eventItem.event_date).setHours(0, 0, 0, 0) + 3 * 24 * 60 * 60 * 1000) <
		new Date(new Date().setHours(0, 0, 0, 0));

	useEffect(() => {
		if (eventItem.service_id) {
			const fetchServices = async () => {
				try {
					const serviceIds = JSON.parse(eventItem.service_id);

					if (Array.isArray(serviceIds)) {
						const servicePromises = serviceIds.map((id) => {
							return getRequest(`/bookings/service/${id}`);
						});

						const serviceResponses = await Promise.all(servicePromises);
						setServices(serviceResponses.flat());
					} else {
						console.error('service_id is not an array');
					}
				} catch (error) {
					console.error('Error parsing service_id:', error);
				}
			};

			fetchServices();
		}
	}, [eventItem.service_id]);

	const handleMouseEnter = () => {
		onHover(eventItem.booking_entity_id);
		setIsHovered(true);
	};

	const handleMouseLeave = () => {
		onLeave();
		setIsHovered(false);
	};

	const cardStyle = {
		backgroundColor: 'rgb(255, 255, 255)',
		padding: 0,
		paddingBottom: '18px',
		borderRadius: '8px',
		border: '1px solid rgba(203, 205, 215, 0.231372549)',
		boxShadow: isHovered ? '0px 2px 4px rgba(0, 0, 0, 0.2)' : 'none',
	};

	const renderServiceButton = (service: any) => {
		if (service.service_code) {
			if (service.service_price >= 0) {
				const price = eventItem.event_price > 0 ? eventItem.event_price : service.service_price;
				const label =
					price > 0
						? price % 100 === 0
							? `£${price / 100}`
							: new Intl.NumberFormat('en-GB', {
									style: 'currency',
									currency: 'GBP',
							  }).format(price / 100)
						: null;
				return (
					<Button
						label={label ? `Book ${label}` : 'Book'}
						onClick={() => onOpenModal(service, eventItem, eventItem.access_code)}
						fullWidth
					/>
				);
			} else {
				return (
					<Button
						label="Book"
						onClick={() => onOpenModal(service, eventItem, eventItem.access_code)}
						fullWidth
					/>
				);
			}
		} else if (service.productUrl) {
			const label = service.service_price
				? `Book ${
						service.service_price % 100 === 0
							? `£${service.service_price / 100}`
							: new Intl.NumberFormat('en-GB', {
									style: 'currency',
									currency: 'GBP',
							  }).format(service.service_price / 100)
				  }`
				: 'Details';
			return (
				<a href={service.productUrl} target="_blank" rel="noopener noreferrer">
					<Button label={label} fullWidth />
				</a>
			);
		} else {
			return service.service_price ? <p className="mb-0">from {service.service_price}</p> : null;
		}
	};

	const toggleServices = () => setShowAllServices(!showAllServices);

	return (
		<div
			className="px-3 py-3 mb-3"
			style={cardStyle}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
		>
			{/* Card top */}
			<div className="d-flex flex-column flex-md-row card-content-container">
				{/* Image */}
				<div className="col-12 col-sm-12 col-md-5 col-xl-4">
					<div className="image-container">
						<img
							src={`/img/group-testing/${eventItem.event_image}`}
							alt={eventItem.event_name}
							style={{ objectFit: 'contain' }}
						/>
					</div>
				</div>

				{/* Content */}
				<div className="col-12 col-sm-12 col-md-7 col-xl-8 ps-sm-3 pt-4 pt-md-0">
					<div className="d-flex align-items-center mb-15">
						<div className="col-9">
							<h4 className="mb-2">{eventItem.event_name}</h4>
							<h5 className="mb-0">
								Date/Time:{' '}
								{new Date(eventItem.event_date).toLocaleDateString('en-GB', {
									day: '2-digit',
									month: 'short',
									year: '2-digit',
								})}{' '}
								, {eventItem.event_time}
							</h5>
							<p className="mb-0" style={{ color: '#666' }}>
								Location: {eventItem.event_location}
							</p>
							{isEventFinished && (
								<div
									className="alert alert-secondary"
									style={{
										padding: '10px 18px',
										width: 'fit-content',
										margin: '10px 0 0 0',
										color: '#646464',
									}}
									role="alert"
								>
									<i className="fas fa-info-circle"></i> This event has now finished
								</div>
							)}
						</div>
						<div className="col-3">
							{eventItem.external_url && (
								<a href={eventItem.external_url} target="_blank" rel="noopener noreferrer">
									<Button label="Website" fullWidth variant="border" />
								</a>
							)}
						</div>
					</div>
					{eventItem.description && <ReadMore content={eventItem.description} />}
					<p>{eventItem.description_continued}</p>
					<p>{eventItem.disclaimer}</p>
				</div>
			</div>

			{/* Services */}
			{!isEventFinished && (
				<div>
					{services.slice(0, showAllServices ? services.length : 3).map((service, index) => (
						<div key={index} className="card-service d-flex px-3 mt-3">
							{renderServiceButton(service) ? (
								<>
									<div className="col-8 col-lg-9">
										<h6 style={{ fontSize: '16px', marginBottom: '3px' }}>
											{service.service_name}
										</h6>
										<p className="mb-0 pe-3">
											<ReadMore content={`${service.description}`} limit={140} />
										</p>
									</div>
									<div className="col-4 col-lg-3 justify-content-end text-end align-self-center">
										{renderServiceButton(service)}
									</div>
								</>
							) : (
								<div className="col-12">
									<h6 style={{ fontSize: '16px', marginBottom: '3px' }}>{service.service_name}</h6>
									<p className="mb-0 pe-3">{service.description}</p>
								</div>
							)}
						</div>
					))}

					{services.length > 3 && (
						<button
							className={`view-more-button pb-2 ${showAllServices ? 'expanded' : ''}`}
							onClick={toggleServices}
						>
							{showAllServices ? 'View Less' : 'View all'}
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								strokeWidth="2"
								strokeLinecap="round"
								strokeLinejoin="round"
							>
								<polyline points="6 9 12 15 18 9"></polyline>
							</svg>
						</button>
					)}
				</div>
			)}
		</div>
	);
}
