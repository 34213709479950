import React, { useContext, useEffect, useRef, useState } from 'react';
import { GoogleMap, InfoWindow, Marker } from '@react-google-maps/api';
import { BookingContext, Button } from '@sirkka-health/booking-system-ui';
import { useNavigate } from 'react-router-dom';

const EventModalContent = ({
	type,
	selectedServiceItem,
	selectedEntityItem,
	serviceData,
	addToCart,
	accessCode,
}) => {
	const [showFullModalDescription, setShowFullModalDescription] = useState(false);
	const [activeMarker, setActiveMarker] = useState(null);
	const [map, setMap] = useState(null);
	const contentRef = useRef(null);
	const [mapHeight, setMapHeight] = useState('100%');
	const [loading, setLoading] = useState(true);
	const [proclarixAcknowledged, setProclarixAcknowledged] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [accessCodeInputVisible, setAccessCodeInputVisible] = useState(false);
	const [enteredAccessCode, setEnteredAccessCode] = useState('');
	const [accessCodeError, setAccessCodeError] = useState('');

	const { bookingData, setBookingData } = useContext(BookingContext);
	const navigate = useNavigate();

	useEffect(() => {
		if (selectedServiceItem && selectedServiceItem.service_id && serviceData) {
			setLoading(false);
		} else {
			setLoading(true);
		}
	}, [selectedServiceItem, serviceData]);

	useEffect(() => {
		if (contentRef.current) {
			const updateMapHeight = () => {
				const isMobile = window.innerWidth <= 768;
				const newHeight = isMobile ? window.innerHeight + 'px' : '100%';
				setMapHeight(newHeight);
			};

			const resizeObserver = new ResizeObserver(updateMapHeight);
			resizeObserver.observe(contentRef.current);

			// Initial update
			updateMapHeight();

			return () => resizeObserver.disconnect();
		}
	}, []);

	const handleOnLoad = (map) => {
		setMap(map);
		const bounds = new window.google.maps.LatLngBounds();
		const markerPosition = {
			lat: parseFloat(selectedEntityItem.latitude),
			lng: parseFloat(selectedEntityItem.longitude),
		};
		bounds.extend(markerPosition);

		if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
			// If there is only one marker, set zoom level to 15
			map.setCenter(markerPosition);
			map.setZoom(15);
		} else {
			map.fitBounds(bounds);
		}
	};

	const defaultIcon = {
		path: 'M384 192c0 87.4-117 243-168.3 307.2c-12.3 15.3-35.1 15.3-47.4 0C117 435 0 279.4 0 192C0 86 86 0 192 0S384 86 384 192z',
		fillColor: 'white',
		fillOpacity: 1,
		strokeColor: 'black',
		strokeWeight: 2,
		scale: 0.07,
		anchor: new window.google.maps.Point(192, 384),
	};

	function stringToArray(str) {
		try {
			if (!str || typeof str !== 'string') {
				return str;
			}
			const trimmedString = str.trim();
			if (trimmedString.startsWith('[') && trimmedString.endsWith(']')) {
				const validJsonString = trimmedString.replace(/'/g, '"');
				const arr = JSON.parse(validJsonString);
				if (Array.isArray(arr)) {
					return arr;
				}
				throw new Error('The input string is not formatted as an array.');
			} else {
				return [str];
			}
		} catch (err) {
			console.error('Error:', err.message);
			return [];
		}
	}

	const handleToggleDescription = () => {
		setShowFullModalDescription(!showFullModalDescription);
	};

	const eventAddToCart = async (event, service, newServiceType) => {
		const bookingId = Date.now().toString();

		setBookingData([
			...bookingData,
			{
				booking: {
					eventId: event.event_id,
					bookingId: bookingId,
					locationId: null,
					bookingProduct: service.service_name,
					appointmentLocation: `${event.event_name}, ${event.event_location}`,
					appointmentDate: newServiceType === 'Clinic' ? event.event_date : '',
					appointmentTime:
						event.requireAvailability === false && newServiceType === 'Clinic'
							? event.event_time
							: '',
					appointmentRegion: event.event_name,
					appointmentFor: 'Myself',
					completed: false,
					virtualOrPhysical: '',
					termsconditions: '',
					bookingType: type,
					requireAvailability: event.require_availability,
					accountTo: event.event_organiser ? event.event_organiser : 'Sirkka Health',
				},
				customer: {
					nameTitle: '',
					firstName: '',
					lastName: '',
					gender: '',
					dateOfBirth: '',
					phoneNumber: '',
					company: '',
					occupation: '',
					address_type: 'home',
				},
				postal: {
					postageAddressLine1: '',
					postageAddressLine2: '',
					postageCity: '',
					postagePostcode: '',
					postageCountry: '',
					postageSelection: 'no',
				},
				service: {
					serviceId: service.service_code,
					serviceName: service.service_name,
					regionId: null,
					icon: service.service_icon,
					emailTemplate: service.email_template,
					price: event.event_price > 0 ? event.event_price : service.service_price, // Event price takes precedence over service price
					apptStackable: service.appt_stackable,
					apptDuration: service.appt_duration,
					serviceType: newServiceType,
				},
				entity: {
					entityId: event.booking_entity_id,
					entityName: event.event_organiser,
				},
			},
		]);

		navigate('/cart');
	};

	const handleBookEvent = () => {
		if (
			selectedServiceItem.service_code === 'proclarixCambridge' &&
			proclarixAcknowledged === false
		) {
			setErrorMessage('Please confirm this before booking.');
		} else if (accessCode) {
			setAccessCodeInputVisible(true);
		} else {
			eventAddToCart(selectedEntityItem, selectedServiceItem, 'Clinic');
		}
	};

	const handleProclarixAcknowledgedCheckbox = () => {
		setProclarixAcknowledged((prevState) => !prevState);
		setErrorMessage('');
	};

	const handleAccessCodeSubmit = () => {
		if (enteredAccessCode === accessCode) {
			setAccessCodeInputVisible(false);
			eventAddToCart(selectedEntityItem, selectedServiceItem, 'Clinic');
		} else {
			setAccessCodeError('The access code you entered is incorrect.');
		}
	};

	if (loading) {
		return <div>Loading service data...</div>;
	}

	const hasDescription =
		selectedServiceItem?.first_description ||
		selectedServiceItem?.bullets ||
		selectedServiceItem?.description_continued;

	// Price set for event will be precedence over service price
	const price =
		selectedEntityItem?.event_price > 0
			? selectedEntityItem.event_price
			: selectedServiceItem?.service_price;

	return (
		<div className="container">
			<div className="row">
				<h3>
					{selectedEntityItem.event_name} - {selectedServiceItem.service_name}
				</h3>
				{!accessCodeInputVisible && (
					<>
						<div className="col-12 col-lg-6">
							<hr style={{ borderBottom: '1px solid #000', margin: '0 0 26px 0' }} />
							<div ref={contentRef}>
								<div>
									<h5>Event details</h5>
									{hasDescription ? (
										<>
											<div
												className="modalDescription"
												style={{
													height: showFullModalDescription ? 'auto' : `100px`,
													overflow: 'hidden',
													position: 'relative',
													boxSizing: 'border-box',
												}}
											>
												<div
													style={{
														position: 'relative',
														paddingBottom: showFullModalDescription ? 0 : '50px',
													}}
												>
													{selectedServiceItem.first_description &&
														stringToArray(selectedServiceItem.first_description).map(
															(description, index) => (
																<p key={`top-description-${index}`}>{description}</p>
															)
														)}
													<ul>
														{selectedServiceItem.bullets &&
															stringToArray(selectedServiceItem.bullets).map((bullet, index) => (
																<li key={`bullet-${index}`} style={{ listStyle: 'disc inside' }}>
																	{bullet}
																</li>
															))}
													</ul>

													{selectedServiceItem.description_continued &&
														stringToArray(selectedServiceItem.description_continued).map(
															(description, index) => (
																<p
																	key={`bottom-description-${index}`}
																	style={{ margin: '12px 0 0 0' }}
																>
																	{description}
																</p>
															)
														)}
													{selectedServiceItem.disclaimers &&
														stringToArray(selectedServiceItem.disclaimers).map(
															(description, index) => (
																<p
																	key={`bottom-description-${index}`}
																	style={{
																		margin: '12px 0 0 0',
																		color: '#8d8d8d',
																		fontSize: '13px',
																	}}
																>
																	{description}
																</p>
															)
														)}
												</div>
												{!showFullModalDescription && (
													<div
														style={{
															position: 'absolute',
															bottom: 0,
															left: 0,
															right: 0,
															height: '50px',
															backgroundImage:
																'linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1))',
														}}
													/>
												)}
											</div>
											<button
												className="btn btn-link d-block mx-auto mb-3 text-black"
												onClick={handleToggleDescription}
											>
												{showFullModalDescription ? 'Show Less' : 'Show More'}
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="24"
													height="24"
													viewBox="0 0 24 24"
													fill="none"
													stroke="currentColor"
													strokeWidth="2"
													strokeLinecap="round"
													strokeLinejoin="round"
												>
													<polyline points="6 9 12 15 18 9"></polyline>
												</svg>
											</button>
										</>
									) : null}
								</div>
								<hr style={{ borderBottom: '1px solid #000', margin: '0 0 26px 0' }} />
								<div>
									<h5>Location</h5>
									<p>{selectedEntityItem.event_location}</p>
								</div>

								<hr style={{ borderBottom: '1px solid #000', margin: '0 0 26px 0' }} />
								<div>
									<h5>Date & Time</h5>
									<p>
										{new Date(selectedEntityItem.event_date).toLocaleDateString('en-GB', {
											weekday: 'long',
											day: '2-digit',
											month: 'short',
											year: 'numeric',
										})}
										<br />
										{selectedEntityItem.event_time}
									</p>
								</div>
								<hr style={{ borderBottom: '1px solid #000', margin: '0 0 26px 0' }} />
								{selectedEntityItem.phone_number ||
									selectedEntityItem.email_address ||
									(selectedEntityItem.external_url && (
										<>
											<div>
												<h5>Contact details</h5>
												<p>
													{selectedEntityItem.phone_number && (
														<>
															<a href={`tel:+44${selectedEntityItem.phone_number.slice(2)}`}>
																{`+44 (0) ${selectedEntityItem.phone_number.slice(
																	2,
																	6
																)} ${selectedEntityItem.phone_number.slice(
																	6,
																	9
																)} ${selectedEntityItem.phone_number.slice(9, 12)}`}
															</a>
															<br />
														</>
													)}
													{selectedEntityItem.email_address && (
														<>
															<a href={`mailto:${selectedEntityItem.email_address}`}>
																{selectedEntityItem.email_address}
															</a>
															<br />
														</>
													)}
													{selectedEntityItem.external_url && (
														<a href={selectedEntityItem.external_url}>
															{selectedEntityItem.external_url}
														</a>
													)}
												</p>
											</div>

											<hr style={{ borderBottom: '1px solid #000', margin: '26px 0 26px 0' }} />
										</>
									))}
							</div>
						</div>
						<div className="col-12 col-lg-6">
							<div style={{ position: 'sticky', top: '0px', height: mapHeight }}>
								<GoogleMap
									onLoad={handleOnLoad}
									onClick={() => setActiveMarker(null)}
									mapContainerStyle={{ width: '100%', height: '100%' }}
									options={{
										zoomControl: false,
										mapTypeControl: false,
										scaleControl: false,
										streetViewControl: false,
										rotateControl: false,
										fullscreenControl: false,
									}}
								>
									<Marker
										position={{
											lat: parseFloat(selectedEntityItem.latitude),
											lng: parseFloat(selectedEntityItem.longitude),
										}}
										icon={defaultIcon}
									>
										{activeMarker === 1 ? (
											<InfoWindow onCloseClick={() => setActiveMarker(null)}></InfoWindow>
										) : null}
									</Marker>
								</GoogleMap>
							</div>
						</div>
						{selectedServiceItem.service_code === 'proclarixCambridge' && (
							<>
								<div style={{ margin: '22px 0' }}>
									<label style={{ display: 'flex', alignItems: 'center' }}>
										<input
											type="checkbox"
											style={{ transform: 'scale(0.8)', width: '5%' }} // Adjust the scale factor as needed
											checked={proclarixAcknowledged}
											onChange={() => handleProclarixAcknowledgedCheckbox()}
										/>
										<span style={{ marginLeft: '8px' }}>
											I confirm that my PSA is between 2-10ng/ml, and I accept that if the test
											shows my PSA to be outside the range of 2-10ng/ml I will just receive my Total
											and Free PSA result.
										</span>
									</label>
									{errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
								</div>
							</>
						)}
						<div>
							<h5 className="pt-4 pb-1 py-lg-0">Your booking options</h5>

							{/* For Postal */}
							{selectedServiceItem.service_type.includes('Postal') && (
								<div
									className="d-flex align-items-center"
									style={{
										backgroundColor: '#f2f2f2',
										padding: '12px',
									}}
								>
									<div className="col-6">
										<h6 className="m-0">
											Receive by post -{' '}
											{new Intl.NumberFormat('en-GB', {
												style: 'currency',
												currency: 'GBP',
											}).format(price / 100)}
										</h6>
										<p className="m-0">Delivered to you to take at home.</p>
									</div>
									<div className="col-6 justify-content-end d-flex">
										<Button
											label="Receive by post"
											disabled={false}
											variant="border"
											onClick={() => {
												eventAddToCart(selectedEntityItem, selectedServiceItem, 'Postal');
											}}
										/>
									</div>
								</div>
							)}

							{/* For Self-Collection */}
							{selectedServiceItem.service_type.includes('Postal') && (
								<div className="d-flex align-items-center" style={{ padding: '12px' }}>
									<div className="col-6">
										<h6 className="m-0">
											Collect test-kit from event -{' '}
											{new Intl.NumberFormat('en-GB', {
												style: 'currency',
												currency: 'GBP',
											}).format(price / 100)}
										</h6>
										<p className="m-0">Collect test-kit from event and take at home</p>
									</div>
									<div className="col-6 justify-content-end d-flex">
										<Button
											label="Collect test-kit"
											disabled={false}
											variant="border"
											onClick={() => {
												eventAddToCart(selectedEntityItem, selectedServiceItem, 'Self-Collection');
											}}
										/>
									</div>
								</div>
							)}

							{/* For Clinic */}
							{selectedServiceItem.service_type.includes('Clinic') && (
								<div
									className="d-flex align-items-center"
									style={{
										backgroundColor: '#f2f2f2',
										padding: '12px',
									}}
								>
									<div className="col-6">
										<h6 className="m-0">
											Attend event -{' '}
											{new Intl.NumberFormat('en-GB', {
												style: 'currency',
												currency: 'GBP',
											}).format(price / 100)}
										</h6>
										<p className="m-0">Select your appointment time in the next step</p>
									</div>
									<div className="col-6 justify-content-end d-flex">
										<Button
											label="Book this event"
											disabled={false}
											variant="border"
											onClick={() => {
												handleBookEvent();
											}}
										/>
									</div>
								</div>
							)}
						</div>
					</>
				)}

				{/* Access Code Input */}
				{accessCodeInputVisible && (
					<div className="access-code-input">
						<div className="mt-4">
							<label htmlFor="accessCode">Please enter Access Code</label>
							<input
								type="text"
								value={enteredAccessCode}
								onChange={(e) => setEnteredAccessCode(e.target.value)}
								placeholder="Access code"
								id="accessCode"
							/>
							{accessCodeError && <div style={{ color: 'red' }}>{accessCodeError}</div>}
							<div className="d-flex justify-content-between mt-3">
								<Button
									label="Cancel"
									onClick={() => setAccessCodeInputVisible(false)}
									variant="border"
								/>
								<Button label="Submit" onClick={handleAccessCodeSubmit} />
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default EventModalContent;
